import React from "react"
import { Helmet } from "react-helmet"
import img from "../../static/gamersmancave.png"

const SEO = () => {
  return (
    <Helmet title="">
      <html lang="en"/>
      <title>Gamersmancave Global</title>
        <meta name="description" content="Gamersmancave is a website created by gamers for gamers. The only gaming blog where you find the most honest reviews of games, even when the hype for the game is sky-high, we don't hold back with our objective opinion."/>

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Gamersmancave Global"/>
        <meta itemprop="description" content="Gamersmancave is a website created by gamers for gamers. The only gaming blog where you find the most honest reviews of games, even when the hype for the game is sky-high, we don't hold back with our objective opinion."/>
        <meta itemprop="image" content={img}/>

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://gamersmancave.com"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Gamersmancave Global"/>
        <meta property="og:description" content="Gamersmancave is a website created by gamers for gamers. The only gaming blog where you find the most honest reviews of games, even when the hype for the game is sky-high, we don't hold back with our objective opinion."/>
        <meta property="og:image" content={img}/>

       {/*  <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Gamersmancave Global"/>
        <meta name="twitter:description" content="Gamersmancave is a website created by gamers for gamers. The only gaming blog where you find the most honest reviews of games, even when the hype for the game is sky-high, we don't hold back with our objective opinion."/>
        <meta name="twitter:image" content={img}/>
    </Helmet>
  )
}

export default SEO