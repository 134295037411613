import React from 'react'
import adImg from "../../static/images/ad.jpg"

const maxWidth = 1180
var dynamicWidth = `calc( (100vw - ${maxWidth}px)/2 )`
export default ({ children }) => (
  <>
  <img src={adImg} style={{position: "absolute", top: 0, left: 0, height: "120%", width: dynamicWidth }}/>
    <div style={{ maxWidth: maxWidth, margin: '0 auto' }}>
      {children}
    </div>
  <img src={adImg} style={{position: "absolute", top: 0, right: 0, height: "120%", width: dynamicWidth }}/>
  </>
)
